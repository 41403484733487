import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Button, Row, Alert } from "antd"
import _ from "underscore"

import { useOffer } from "../hooks/hooks"
import { useAuth } from "../hooks/useAuth"
import { useTheme } from "../hooks/useTheme"

import { Spinner } from "../components/core/Spinner"
import GenesisOffer from "../components/lenders/GenesisOffer"
import { useApply } from "../hooks/useApply"
import FortivaOffer from "../components/lenders/FortivaOffer"
import CitizensOffer from "../components/lenders/CitizensOffer"
import OwnLeaseOffer from "../components/lenders/OwnLeaseOffer"
import UownOffer from "../components/lenders/UownOffer"
import FlexshopperOffer from "../components/lenders/FlexshopperOffer"
import CitiOffer from "../components/lenders/CitiOffer"
import PaypalOffer from "../components/lenders/PaypalOffer"
import OfferSummary from "../components/offer/OfferSummary"
// import AffFinwiseSummaryModal from "../components/offer/AffFinwiseSummaryModal"
import SingleOfferCard from "../components/cards/SingleOfferCard"
import AffFinwiseOffer from "../components/offer/AffFinwiseOffer"
import copy from "../utils/appCopy"

const blockOriginatorKeys = [
  "americanfirst",
  "ownlease",
  "flexshopper",
  "flexshopper-external",
  "citi",
  "paypal",
  "citizens",
]
function OfferPage() {
  const { user } = useAuth()
  const history = useHistory()
  const { merchant, getLead, lead, merchantId } = useApply()
  const { setIsShowMenu } = useTheme()
  const { offer_id } = useParams()
  const { error, loading, data } = useOffer(offer_id)

  const [showModal, setShowModal] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOverAmount, setIsOverAmount] = useState(false)

  useEffect(() => {
    if (!error && (!merchant || !lead)) {
      if (data) {
        ;(async () => {
          await getLead(data.lead?.id)
        })()
      }
    }
  }, [data, error, getLead, lead, merchant])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])
  useEffect(() => {
    if (merchantId === "tireagent-flexshopper") {
      setIsShowMenu(false)
    }
    //eslint-disable-next-line
  }, [merchantId])

  useEffect(() => {
    if (data?.status) {
      const { id, status } = data
      window.parent?.postMessage({ type: "offer", id, status }, "*")
    }
    //eslint-disable-next-line
  }, [data?.status])

  useEffect(() => {
    if (lead?.cart !== null && (data || lead)) {
      const offerValue = data ? Number(data.amount.replace(/\D/g, "")) : 0
      const cartValue = Number(lead?.cart?.total ?? 0)

      const isOver = cartValue > 0 && offerValue > 0 && cartValue > offerValue
      setIsOverAmount(isOver)
    } else {
      setIsOverAmount(false)
    }
  }, [data, lead])

  const handleAcceptOffer = async () => {
    if (data.originator.key === "fortiva" && !isSubmit) return
    if (_.has(copy, data.originator.key)) {
      history.push(`/offers/${offer_id}/apply`)
    } else {
      const jwt = sessionStorage.getItem("jwt")
      const url = jwt ? `${data.apply_url}?token=${jwt}` : data.apply_url
      window.location.assign(url)
    }
  }

  if (error) {
    if (error?.response?.status === 404) {
      history.push("/404")
    }
  }

  if (isLoading || !data || !user || !merchant) {
    return <Spinner />
  }

  let component = null

  const acceptButton = (
    <Button type="primary" onClick={handleAcceptOffer}>
      {data.originator.key === "genesis" || data.originator.key === "fortiva"
        ? "Accept Offer"
        : "Apply Now"}
    </Button>
  )

  switch (data.originator.key) {
    case "genesis":
      component = (
        <GenesisOffer
          acceptOffer={handleAcceptOffer}
          offer={data}
          merchant={merchant}
          isOverAmount={isOverAmount}
        />
      )
      break
    case "finwise":
    case "americanfirst":
      component = (
        <AffFinwiseOffer
          offer={data}
          acceptButton={acceptButton}
          isOverAmount={isOverAmount}
        />
      )

      break
    case "fortiva":
      component = (
        <FortivaOffer
          offer={data}
          acceptOffer={handleAcceptOffer}
          setIsSubmit={setIsSubmit}
          isOverAmount={isOverAmount}
        />
      )
      break
    case "citizens":
      component = (
        <CitizensOffer
          offer={data}
          acceptOffer={handleAcceptOffer}
          isOverAmount={isOverAmount}
        />
      )
      break
    case "ownlease":
      component = (
        <OwnLeaseOffer
          offer={data}
          acceptOffer={handleAcceptOffer}
          isOverAmount={isOverAmount}
        />
      )
      break
    case "uown":
      component = (
        <UownOffer
          offer={data}
          acceptOffer={handleAcceptOffer}
          isOverAmount={isOverAmount}
        />
      )
      break
    case "flexshopper":
    case "flexshopper-external":
      component = (
        <FlexshopperOffer
          offer={data}
          acceptOffer={handleAcceptOffer}
          isOverAmount={isOverAmount}
        />
      )
      break
    case "citi":
      component = <CitiOffer offer={data} isOverAmount={isOverAmount} />
      break
    case "paypal":
      component = <PaypalOffer offer={data} isOverAmount={isOverAmount} />
      break
    default:
      component = (
        <SingleOfferCard
          offer={data}
          user={user}
          acceptButton={acceptButton}
          isOverAmount={isOverAmount}
        />
      )
  }

  return (
    <>
      {/* {data?.status === "offered" &&
        ["finwise", "americanfirst"].includes(data.originator.key) && (
          <AffFinwiseSummaryModal
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )} */}

      {data?.status === "offered" &&
        !blockOriginatorKeys.includes(data.originator.key) && (
          <OfferSummary
            offer={data}
            visible={showModal}
            close={() => setShowModal(false)}
          />
        )}

      {data.errors?.map((e) => (
        <Alert
          message={e}
          key={e}
          type="error"
          showIcon
          closable
          style={{ marginBottom: 5 }}
        />
      ))}
      <Row
        justify="center"
        align="middle"
        style={{
          marginLeft: 0,
          textAlign: "center",
          marginTop: 10,
          marginRight: 0,
        }}
      >
        {component}
      </Row>
    </>
  )
}

export default OfferPage
