import React, { useState } from "react"
import { Checkbox } from "formik-antd"
import { Typography, Modal } from "antd"
import { useTheme } from "../../hooks/useTheme"

const { Text } = Typography

const DOCUMENT_KEYS = {
  arbitration: "Arbitration Provision",
  payment_authorization: "Payment Authorization",
  privacy_policy: "Terms of Use & Privacy Policy",
  terms_and_conditions: "Application Terms & Conditions",
  cardholder_agreement: "Cardholder Agreement",
  application_disclosures: "Application Disclosures",
  electronic_disclosure: "Electronic Disclosure",
}

function DocumentModal({ visible, onCancel, doc }) {
  if (doc === null) return null
  return (
    <Modal visible={visible} footer={null} onCancel={onCancel} width="100vw">
      {doc.text !== null && (
        <iframe
          title="Offer"
          height="700"
          frameBorder="0"
          width="100%"
          srcDoc={`<html style="font-family: 'Segoe UI', Roboto, 'Helvetica Neue';">${doc.text}</html>`}
        />
      )}
    </Modal>
  )
}

export default function ComplianceAgree(props) {
  console.log("compliance agree props", props.offer)
  const [visible, setVisible] = useState(false)
  const [activeDoc, setActiveDoc] = useState(null)
  const { isMobile, themeColor } = useTheme()

  if (!props.offer) return null

  const documentObject = {}
  props.offer.documents.forEach((doc) => {
    documentObject[doc.type] = doc
  })

  function handleDocumentClick(doc) {
    if (doc.link) {
      window.open(doc.link, "_blank")
    } else {
      setVisible(true)
      setActiveDoc(doc)
    }
  }

  function DocumentLink({ doc }) {
    // console.log("doc is", doc)
    return (
      <button
        onClick={() => handleDocumentClick(doc)}
        style={{
          background: "none",
          border: "none",
          padding: "0",
          color: themeColor,
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {doc.title || DOCUMENT_KEYS[doc.type]}
      </button>
    )
  }

  return (
    <>
      <DocumentModal
        doc={activeDoc}
        onCancel={() => setVisible(false)}
        visible={visible}
      />
      <div style={{ textAlign: "left" }}>
        <Checkbox
          name="documents"
          validate={(value) => {
            if (value) {
              return undefined
            }
            return "Please agree to continue"
          }}
        />
        &nbsp;&nbsp;
        <Text style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}>
          I agree to the {props.offer.originator.name}{" "}
          {documentObject.terms_and_conditions && (
            <>
              <DocumentLink doc={documentObject.terms_and_conditions} />,{" "}
            </>
          )}
          {documentObject.arbitration && (
            <>
              <DocumentLink doc={documentObject.arbitration} />,{" "}
            </>
          )}
          {documentObject.privacy_policy && (
            <>
              <DocumentLink doc={documentObject.privacy_policy} />,{" "}
            </>
          )}
          {documentObject.electronic_disclosure && (
            <>
              <DocumentLink doc={documentObject.electronic_disclosure} />,{" "}
            </>
          )}
          {documentObject.payment_authorization && (
            <DocumentLink doc={documentObject.payment_authorization} />
          )}
          .
        </Text>
      </div>
      <div
        style={{
          textAlign: "left",
          height: isMobile ? "40vh" : "100%",
          overflow: "auto",
        }}
      >
        <Checkbox
          name="agree_authorize"
          validate={(value) => {
            return undefined
          }}
        />
        &nbsp;&nbsp;
        {documentObject.tcpa && (
          <Text
            style={{ textAlign: "center", fontSize: 12, fontColor: "black" }}
          >
            <DocumentLink doc={documentObject.tcpa} />
          </Text>
        )}
      </div>
    </>
  )
}
