import React, { useMemo } from "react"
import { Button, Alert } from "antd"

export default function OfferOverNotification({ lead, isOverAmount }) {
  const cancelCheckoutBtn = useMemo(() => {
    if (["magento2", "shopify", "snipcart"].includes(lead?.channel))
      return <></>

    return (
      <Button
        onClick={() => window.location.replace(lead?.actions?.cancel)}
        style={{ borderRadius: 7, fontSize: 13 }}
        type="danger"
      >
        Cancel Check Out
      </Button>
    )
  }, [lead])

  if (!isOverAmount) {
    return <></>
  }

  return (
    <Alert
      message={
        <div style={{ fontWeight: "bold" }}>
          Your cart total is greater than your Pre-Qualified Amount
        </div>
      }
      description={`Please select "Cancel Check Out" and edit your total cart value to be
          equal or below your Pre-Qualified amount.`}
      showIcon
      type="error"
      style={{
        marginBottom: 7,
        width: "100%",
        textAlign: "left",
        alignItems: "center",
      }}
      action={cancelCheckoutBtn}
    />
  )
}
